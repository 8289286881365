import React, { useEffect, useState, useMemo, useContext, useRef } from "react";
import MainWrapper from "../../MainWrapper";
import Title from "../../elements/Title";
import { Icon } from "react-icons-kit";
import { Row, Col, Form, Table, Modal, Button } from 'react-bootstrap';
import { ic_arrow_back } from "react-icons-kit/md";
import {trash} from 'react-icons-kit/fa/trash'
import {buildingO} from 'react-icons-kit/fa/buildingO';
import { Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { STORE_INFO_REQUEST } from "../../../_utils/constants/admin/StoreInfo";
import { CUSTOMER_LIST_REQUEST, 
	CUSTOMER_UPDATE_REQUEST,
	CUSTOMER_STATE_CHANGE, 
	CUSTOMER_IMPORT_REQUEST,
 	CUSTOMER_IMPORT_IMAGE_REQUEST,
  CUSTOMER_GET_IMAGE_REQUEST,
  CUSTOMER_IMPORT_UNIT_MIX_SAVE_REQUEST,
	CUSTOMER_UNIT_MIX_LIST_REQUEST,
	CUSTOMER_UPDATE_UNIT_MIX_REQUEST,
	CUSTOMER_DELETE_UNIT_TYPE_REQUEST
 } from "../../../_utils/constants/Customer";
import { SALES_PERSON_CATEGORY_REQUEST, SALES_PERSON_REQUEST } from "../../../_utils/constants/SalesPerson";
import { CONTACT_ROLL_LIST_REQUEST } from "../../../_utils/constants/ContactRoll";
import { ROLE_LIST_REQUEST } from "../../../_utils/constants/Role";
import Input from "../../elements/CustomInput";
import Select from "../../elements/CustomSelect";
import CustomButton from "../../elements/CustomButton";
import { toast } from 'react-toastify';
import PricingContract from "./models/PricingContract";
import ImportCustomer from "./models/ImportCustomer";
import UnitMix from "./models/UnitMix";
import ImportImage from "./models/ImportImage";
import SelectOrderMaterial from "./models/SelectOrderMaterial";
import {Context} from "../../../App";
import EditLayoutModal from "./models/EditLayoutModal";
import { PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST, PRICING_CONTRACT_QUANTITY_SET_PRODUCT_REQUEST } from "../../../_utils/constants/PricingContract";
import MeasureModal from "./models/MeasureModal";
import { seamingUrl } from "../../../config";
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

const CustomerDetail = ({
	auth: { auth, isAuthenticated },
	customerInfo: { customerList, imageList, unitMixData, isLoading, isImageDataLoaded, isEdited, importedData, isImported, isSaveImportedData, saveImportedData, errors, customerUnitMixList, customerUpdatedUnitMix, customerDeletedUnitType },
	pricingContract: { isCreated, missingStairsLaborContractList, getQuantityProduct },
	StoreInfo: { storeInfo },
	Role: { roleList },
	fetchCustomerList,
	updateCustomerDetails,
	changeReducerState,
	fetchStoreInfo,
	SalesPersonCategory: { salesPersonCategory, salesPerson },
	fetchSalesPersonCategory,
	ContactRoll: { contactRollList },
	fetchCarpetRollList,
	submitImportCustomerRequest,
	saveImportCustomerUnitMixRequest,
	fetchUploadedImageRequest,
	submitImportImageCustomerRequest,
	fetchUnitMixListRequest,
	updateUnitMixListRequest,
	fetchRoleList,
	fetchMissingLaborItemsInContract,
	getQuantitySetProduct,
	deleteUnitTypeRequest,
	fetchSalesPersonByStoreId
}) => {

	const { id } = useParams();
	const [pricingContractModalOpen, setPricingContractModalOpen] = useState(false);
	const [importModalOpen, setImportModalOpen] = useState(false);
	const [unitMixModalOpen, setUnitMixModalOpen] = useState(false);
	const [contactViewModel, setContactViewModel] = useState([]);
	const [storeId, setStoreId] = useState('');
	const [importedFileData, setImportedFileData] = useState();
	const [importedFileMessageData, setImportedFileMessageData] = useState();
	const [saveFileMessageData, setSaveFileMessageData] = useState();
	const [orderingModalOpen, setOrderingModalOpen] = useState(false);
	const [orderingUrl, setOrderingUrl] = useState();
	const [defaultlayoutData, setDefaultlayoutData] = useState([]);
	const [processStart, setProcessStart] = useState(false);
	const [editlayoutModalOpen, setEditlayoutModalOpen] = useState(false);
	const [customerUnitMixData, setCustomerUnitMixData] = useState([]);
	const [roleListDetails, setRoleListDetails] = useState([]);
	const [duplicatesUnitData, setDuplicatesUnitData] = useState([]);
	const [measureModalOpen, setMeasureModalOpen] = useState(false);
	const [measureUrl, setMeasureUrl] = useState();
	const [selectedQuantityProduct, setSelectedQuantityProduct] = useState([]);
	const [deleteUnitTypeModalOpen, setDeleteUnitTypeModalOpen] = useState(false);
	const [deleteUnitTypeData, setDeleteUnitTypeData] = useState([]);
	const [userDetail, setUserDetail] = useState({
		"contactInfoId": id,
		"shipToPropertyName": "",
		"shipToManagementCompany": "",
		"shipToAddress": "",
		"shipToCity": "",
		"shipToState": "",
		"shipToZip": "",
		"shipToPhone": "",
		"billToName": "",
		"billToAddress": "",
		"billToCity": "",
		"billToState": "",
		"billToZip": "",
		"billToPhone": "",
		"billToEmail": "",
		"storeId": "",
		"storeName": "",
		"salesPersonId": "",
		"ilContactViewModel": [],
		"isContractPricingAdded" : ""
	})

	const {pushervalue} = useContext(Context);

	const navigate = useNavigate();

	// handle value for tooltip
	const target = useRef(null);

	useEffect(() => {
		if (!isAuthenticated) {
			navigate("/login")
		}
	}, [auth, isAuthenticated])

	// function to call customer detail api
	useEffect(() => {
		if (isAuthenticated) {
			fetchCustomerList(auth[0].token, "asc", 1, 1, { 'contactInfoId': id }, auth[0].userId, false, false, false);
			fetchStoreInfo(auth[0].token);
			fetchSalesPersonCategory(auth[0].token);
			// fetchSalesPersonByStoreId(auth[0].token);
			fetchCarpetRollList(auth[0].token);
			fetchRoleList(auth[0].token);
			fetchUploadedImageRequest(auth[0]?.token, "asc", 1, 20, id, auth[0]?.userId)
			setContactViewModel()
			fetchMissingLaborItemsInContract(auth[0].token, id)
			getQuantitySetProduct(auth[0].token, id)
		}
		if (!isAuthenticated) return <Navigate to="/login" />;
	}, [auth, isAuthenticated]);

	// function called to show success message for contract creation and closing the modal
	useEffect(()=> {
		if (isCreated === true) {
			fetchCustomerList(auth[0].token, "asc", 1, 1, { 'contactInfoId': id }, auth[0].userId, false, false, false);
		}
	}, [auth, isCreated])
	
	// function called to modify customer listing
	useEffect(() => {
		localStorage.setItem('Customer-Listing', 'on');
	}, [])

	// function called on form response
	useEffect(() => {
		if (isAuthenticated) {
			if (isEdited) {
				toast.success("Customer data updated successfully.");
				setUnitMixModalOpen(false);
				setDuplicatesUnitData();
				changeReducerState();
			}
		}
	}, [isEdited, auth, isAuthenticated]);

	// function to set users data
	useEffect(() => {
		if (customerList.length > 0) {
			// let allData = contactViewModel;
			customerList.map((item, index) => {
				setUserDetail({
					"contactInfoId": item.contactInfoId,
					"shipToPropertyName": item.shipToPropertyName,
					"shipToManagementCompany": item.shipToManagementCompany,
					"shipToAddress": item.shipToAddress,
					"shipToCity": item.shipToCity,
					"shipToState": item.shipToState,
					"shipToZip": item.shipToZip,
					"shipToPhone": item.shipToPhone,
					"billToName": item.billToName,
					"billToAddress": item.billToAddress,
					"billToCity": item.billToCity,
					"billToState": item.billToState,
					"billToZip": item.billToZip,
					"billToPhone": item.billToPhone,
					"billToEmail": item.billToEmail,
					"storeId": item.storeId,
					"storeName": item.storeName,
					"salesPersonId": item.salesPersonId,
					"ilContactViewModel": item.ilContactViewModel,
					"isContractPricingAdded" : item.isContractPricingAdded,
					"viableSeamsFloorPlans" : item.viableSeamsFloorPlans
				})
				setStoreId(item.storeId);
				let tempArr = []
				if (item.ilContactViewModel && item.ilContactViewModel.length > 0) {
					item.ilContactViewModel.map((dataItem, dataIndex) => {
						if (item.ilContactViewModel[dataIndex]) {
							tempArr.push({
								"approvalRank": item.ilContactViewModel[dataIndex].approvalRank,
								"roll": item.ilContactViewModel[dataIndex].roll,
								"name": item.ilContactViewModel[dataIndex].name,
								"number": item.ilContactViewModel[dataIndex].number,
								"ext": item.ilContactViewModel[dataIndex].ext,
								"email": item.ilContactViewModel[dataIndex].email,
							})
						}
					})
					// handle condition to show minimum 6 rows in UI
					if (item.ilContactViewModel.length < 6) {
						let count = 6 - item.ilContactViewModel.length
						if (count) {
							_.times(count, (index) => {
								tempArr.push({
									"approvalRank": "",
									"roll": "",
									"name": "",
									"number": "",
									"ext": "",
									"email": "",
								})
							})
						}
					}
					setContactViewModel(tempArr);
				}
			})
			// Handle empty case to show 6 fields data
			if (customerList[0] && customerList[0].ilContactViewModel && customerList[0].ilContactViewModel.length < 1) {
				let tempArr = []
				let count = 6
				_.times(count, (index) => {
					tempArr.push({
						"approvalRank": "",
						"roll": "",
						"name": "",
						"number": "",
						"ext": "",
						"email": "",
					})
				})
				setContactViewModel(tempArr);
			}
		}
	}, [customerList])

	useEffect(() => {
		if(storeId){
			fetchSalesPersonByStoreId(auth[0].token, storeId);
		}
	}, [storeId]);

	const imageListing = useMemo(() => imageList, [imageList])

	useMemo(() => {
		if(imageListing) {
			let tempData = []
			imageListing.map((data, index) => {
				if(data?.measureImagePng) {
					tempData.push(data)
				}
			})
			setDefaultlayoutData(tempData)
		}
	}, [imageListing])

	// function called to close ordering modal
	const handleOrderingModelClose = () => {
		setOrderingModalOpen(false)
		getQuantitySetProduct(auth[0].token, id)
	}

	// handle pusher connection
	useMemo(() => {
		if (!auth || !auth[0] || !auth[0].uniqueChannelName) {
			navigate("/login")
		} else {
			let channel = pushervalue.subscribe(auth[0].uniqueChannelName);
			channel.bind("update-order-status", (data) => {
				if(data?.PusherTrigger === "EXECUTED") {
					fetchMissingLaborItemsInContract(auth[0].token, id)
					fetchUploadedImageRequest(auth[0]?.token, "asc", 1, 20, id, auth[0]?.userId)
					getQuantitySetProduct(auth[0].token, id)
				}
				if(data?.PusherTrigger === "close-ordering-popup") {
					handleOrderingModelClose()
					getQuantitySetProduct(auth[0].token, id)
				}
			});
		}
  }, [processStart]);

	// function to get quantity product
	useEffect(() => {
		if (getQuantityProduct) {
			setSelectedQuantityProduct(getQuantityProduct)
		}
	}, [getQuantityProduct])

	// function for delete unit type updation
	useEffect(() => {
		if (customerDeletedUnitType && customerDeletedUnitType.length > 0) {
			if (customerDeletedUnitType[0] === "deleted") {
				setDeleteUnitTypeModalOpen(false)
				toast.success('Floor plan deleted successfully.');
				fetchUploadedImageRequest(auth[0]?.token, "asc", 1, 20, id, auth[0]?.userId)
			}
		}
	}, [customerDeletedUnitType])

	// function to count duplicate count
	function getDuplicateCount(array, value) {
    return array.filter((v) => (v === value)).length;
	}

	// handle duplicates data
	const handleDuplicatesData = (data) => {
		let value = data.reduce((a, e) => {
			a[e.unitName] = ++a[e.unitName] || 0;
			return a;
		}, {});
		let duplicatesData = data.filter(e => value[e.unitName])
		let duplicateNames = []
		if (duplicatesData && duplicatesData.length > 0) {
			duplicatesData.map(duplicateValue => {
				duplicateNames.push(duplicateValue.unitName)
			})
		}
		let uniqueValue = [...new Set(duplicateNames)]
		let duplicateUnitData= []
		uniqueValue.map(data => {
			duplicateUnitData.push({'name': data, 'count': getDuplicateCount(duplicateNames, data)})
		})
		setDuplicatesUnitData(duplicateUnitData)
	}

	// function to get customer unit mix data
	useEffect(() => {
		if (customerUnitMixList) {
			handleDuplicatesData(customerUnitMixList)
			setCustomerUnitMixData(customerUnitMixList)
		}
	}, [customerUnitMixList])

	// function to get customer unit mix data
	useEffect(() => {
		if (customerUpdatedUnitMix) {
			fetchUploadedImageRequest(auth[0]?.token, "asc", 1, 20, id, auth[0]?.userId)
			setEditlayoutModalOpen(false)
		}
	}, [customerUpdatedUnitMix])

	// function to manage salesperson list data fetched
	// const salesPersonData = useMemo(() => {
	// 	if (salesPersonCategory)
	// 		return salesPersonCategory.map(v => ({ label: v.userName, value: v.userId }))
	// }, [salesPersonCategory])
	const salesPersonData = useMemo(() => {
		if (salesPerson){
			return salesPerson.map(v => ({ label: v.UserName, value: v.UserId }))
		}
		else{
			return []
		}
	}, [salesPerson])


	// setting sales person list data using memo
	const salesPersonList = useMemo(() => {
    let stores = []; //[{ value: 0, label: "All" }];
    return stores.concat(salesPersonData);
  }, [salesPersonData])

	// const contactRoleList = 
	useMemo(() => {
		if (roleList) {
			let roleData = []
			roleList.map(data => {
				if (data.roleType === "Customer") {
					roleData.push({ label: data.roleName, value: data.roleId })
				}
			})
			setRoleListDetails(roleData)
		}
	}, [roleList]);

	// function called on input change event
	const handleChange = (e) => {
		const { id, value } = e.target;
		setUserDetail(
			{
				...userDetail,
				[id]: value
			}
		)
	}
	
	// function called on form submit
	const handleFormSave = (e) => {
		e.preventDefault();
		userDetail.ilContactViewModel = []
		contactViewModel.map((v, i) => {
			userDetail.ilContactViewModel.push(v)
		})
		updateCustomerDetails(auth[0].token, userDetail);
	}

	// function to open pricing contract modal
	const showPricingContractModal = () => {
		localStorage.removeItem("selectedSundriesList")
		setPricingContractModalOpen(true);
	};

	// function to close pricing contract modal
	const hidePricingContractModal = () => {
		setPricingContractModalOpen(false);
	};

	// function to manage store list data fetched
	const storeList = useMemo(() => {
		if (storeInfo)
			return storeInfo.map(v => ({ label: v.storeName, value: v.storeId }))
	}, [storeInfo])

	// setting store list data using memo
	const storeData = useMemo(() => {
    var stores = []; //[{ value: 0, label: "All" }];
    return stores.concat(storeList);
  }, [storeList])

	// function to upload unit mix
	const showUploadModal = () => {
		setImportModalOpen(true);
	};

	// function to close import modal
  const hideImportModal = () => {
    setImportModalOpen(false);
    changeReducerState();
  };

	// function to handle import file
  const handleImportFile = (data) => {
  	let formdata = new FormData();
		formdata.append("files", data[0], data[0].name);
		formdata.append("StoreId", storeId);
		formdata.append("UserId", auth[0].userId);
		formdata.append("contactInfoId", userDetail.contactInfoId);
  	submitImportCustomerRequest({'token': auth[0].token, 'data': formdata})
  	setSaveFileMessageData();
  	setImportedFileData();
  };

  useEffect(() => {
  	if(isImported) {
  		if(importedData) {
  			if(importedData[0]?.fileData) {
  				setImportedFileData(JSON.parse(importedData[0].fileData))
  				setImportedFileMessageData(importedData[0])
  			} else {
  				setImportedFileData()
  				setImportedFileMessageData(importedData[0])
  			}
  			setUnitMixModalOpen(true);
		  	setImportModalOpen(false);
		    changeReducerState();
  		}
  	}
  }, [isImported])

  useEffect(() => {
  	if(isSaveImportedData) {
  		setSaveFileMessageData(saveImportedData);
  		setUnitMixModalOpen(false);
  		changeReducerState();
  	}
  }, [isSaveImportedData])

  useMemo(() => {
    if(!_.isEmpty(errors)) {
      toast.error(errors?.data?.title);
      changeReducerState();
    }
  }, [errors])

  useEffect(() => {
  	if(unitMixData) {
  		fetchUploadedImageRequest(auth[0]?.token, "asc", 1, 20, id, auth[0]?.userId)
  	}
  }, [unitMixData])
  
  // function to handle import modal
  const handleNewImportFile = (data) => {
    setUnitMixModalOpen(false);
    setImportModalOpen(true);
  };

  // function to close unit mix modal
  const hideUnitMixModal = (data) => {
    setUnitMixModalOpen(false);
		setImportedFileData();
		setImportedFileMessageData();
  };

  // function to save unit mix modal
  const handleUnitMixFile = (data) => {
    let dataToString = data
    let payload = {
    	"fileData": JSON.stringify(dataToString)
    }
    saveImportCustomerUnitMixRequest(auth[0].token, payload, id);
  };

	// function called to save contact details
  const handleContactChange = (evt, position) => {
  	let allData = contactViewModel;
		contactViewModel.map((item, index) => {
			if (index === position) {
				if (evt.target.name === 'ApprovalRank') {
					allData[index].approvalRank = evt.target.value;
				}
				if (evt.target.id === 'Roll') {
					allData[index].roll = evt.target.value;
				}
				if (evt.target.name === 'Name') {
					allData[index].name = evt.target.value;
				}
				if (evt.target.name === 'Number') {
					allData[index].number = evt.target.value;
				}
				if (evt.target.name === 'Ext') {
					allData[index].ext = evt.target.value;
				}
				if (evt.target.name === 'Email') {
					allData[index].email = evt.target.value;
				}
				allData = Object.values(allData);
				setContactViewModel(allData);
			}
		})
  }

	// function called on store selection change
	const handleStoreChange = (evt) => {
		setStoreId(evt.target.value);
		// fetchSalesPersonByStoreId(auth[0].token, evt.target.value);
	}

	// function called on salesperson selection change
	const handleSalespersonChange = (evt) => {
		const { id, value } = evt.target;
		setUserDetail({
				...userDetail,
				[id]: parseInt(value)
			})
	}

	// function to submit image data
	const handleImageFile = (file, value) => {
		let formImagedata = new FormData();
		formImagedata.append("file", file[0], file[0].name);
  	submitImportImageCustomerRequest({'token': auth[0].token, 'data': formImagedata }, userDetail.contactInfoId, value?.unitTypeId)
	}

	// function called to fetch customer detail
	const handleUpdateCustomerDetail = () => {
		fetchCustomerList(auth[0].token, "asc", 1, 1, { 'contactInfoId': id }, auth[0].userId, false, false, false);
	}

	// Test function
	const goToEstimatesPageHandler = () => {
		navigate(`/customer-list/customer-detail/estimates/${id}`);
	}

	// function called to handle image name click
	const handleImageNameClick = (data) => {
		if(data?.imagePath) {
			let tempUrl = data?.loginUrl + '&uniqueChannelName='+ auth[0]?.uniqueChannelName
			window.open(tempUrl);
		}
	}

	// function called to open ordering modal
	const handleOrderingModelOpen = () => {
		setOrderingModalOpen(true)
	}

	// handle layout name click
	const handleLayoutNameClick = (data) => {
		setProcessStart(true);
		let tempUrl = data?.orderingLoginUrl + '&uniqueChannelName=' + auth[0].uniqueChannelName
		setOrderingUrl(tempUrl)
		handleOrderingModelOpen()
	}

	// handle default layout save data
	const handleLayoutModalSave = (data) => {
		if (customerUnitMixData && customerUnitMixData.length > 0) {
			let checkFlag = false
			if (imageListing && imageListing.length > 0 && customerUnitMixData && customerUnitMixData.length > 0) {
				imageListing.map(imgValue => {
					customerUnitMixData.map(unitData => {
						if (imgValue.unitTypeId !== unitData.unitTypeId && imgValue.unitType === unitData.unitType) {
							checkFlag = true
						}
					})
				})
			}
			if (checkFlag) {
				toast.error("Unit type already exists");
			} else {
				setCustomerUnitMixData()
				let payload = {
					"unitmixlistdata": data
				}
				updateUnitMixListRequest(auth[0].token, payload)
			}
		}
	}

	// handle default layout name modal close
	const handleLayoutModelClose = () => {
		setEditlayoutModalOpen(false)
		setCustomerUnitMixData()
		setDuplicatesUnitData()
	}

	// handle default layout name modal open
	const handleLayoutModelOpen = () => {
		setEditlayoutModalOpen(true)
	}

	// handle default layout name click
	const handleLayoutNameChange = (value) => {
		let payload = {
			"unitTypeId": value.unitTypeId,
			"contactInfoId": value.contactInfoId
		}
		fetchUnitMixListRequest(auth[0]?.token, payload)
		handleLayoutModelOpen()
	}

	// handle delete unit type
	const handleDeleteUnitType = (value) => {
		setDeleteUnitTypeData(value.unitTypeId)
		setDeleteUnitTypeModalOpen(true)
	}

	// handle measure modal close
	const hideConfirmDeleteModal = () => {
		setDeleteUnitTypeModalOpen(false)
	}

	// handle measure modal close
	const handleConfirmDeleteSubmit = () => {
		let payload = {
			"UnitTypeId": [deleteUnitTypeData]
		}
		deleteUnitTypeRequest(auth[0]?.token, payload)
	}

	// handle measure modal close
	const handleMeasureModelClose = () => {
		setMeasureModalOpen(false)
	}

	// handle measure modal open
	const handleMeasureModelOpen = () => {
		setMeasureModalOpen(true)
	}

	// extract domain from url
	const extractDomainFromUrl = (url) => {
		const urlObj = new URL(url);
		return urlObj.hostname;
	}

	// handle seam and cut plan button click
	const handleSeamAndCutPlan = (data) => {
		if(data?.imagePath) {
			let tempUrl = data?.loginUrl + '&uniqueChannelName='+ auth[0]?.uniqueChannelName
			let domain = extractDomainFromUrl(tempUrl)
			let newUrl = tempUrl.replace(domain, seamingUrl)
			setMeasureUrl(newUrl)
			handleMeasureModelOpen()
		}
	}

	return (
		<MainWrapper>
			{isLoading || isImageDataLoaded ? (<div className="loading-container"><div className="spinner-border"></div></div>) : null}
				<div className="container-fluid">
					<Form onSubmit={handleFormSave}>
						<div className="sub-header mt-5 mb-3">
							<Title>Customer Detail</Title>
							<Link className="btn btn-primary me-1" to="/customer-list"><Icon icon={ic_arrow_back} />Back</Link>
							<CustomButton type="submit" variant="primary">Save</CustomButton>
						</div>
						<Row className="mb-5">
							<Col xl={2} md={6}>
								<h6 className="title mb-4">Ship to:</h6>
								<Row>
									<Col>
										<Form.Group className="mb-2">
											<Input label={`Property Name`} ids={`shipToPropertyName`} value={userDetail.shipToPropertyName} validate={false} handleChange={handleChange} />
										</Form.Group>
										<Form.Group className="mb-2">
											<Input label={`Management company`} ids={`shipToManagementCompany`} value={userDetail.shipToManagementCompany} validate={false} handleChange={handleChange} />
										</Form.Group>
										<Form.Group className="mb-2">
											<Input label={`Address`} ids={`shipToAddress`} value={userDetail.shipToAddress} validate={false} handleChange={handleChange} />
										</Form.Group>
										<Form.Group className="mb-2">
											<Input label={`City`} ids={`shipToCity`} value={userDetail.shipToCity} validate={false} handleChange={handleChange} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-2">
											<Input label={`State`} ids={`shipToState`} value={userDetail.shipToState} validate={false} handleChange={handleChange} />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-2">
											<Input label={`Zip`} ids={`shipToZip`} value={userDetail.shipToZip} validate={false} handleChange={handleChange} />
										</Form.Group>
									</Col>
								</Row><Row>
									<Col>
										<Form.Group className="mb-2">
											<Input label={`Phone`} ids={`shipToPhone`} value={userDetail.shipToPhone} validate={false} handleChange={handleChange} />
										</Form.Group>
									</Col>
								</Row>
							</Col>
							<Col xl={2} md={6}>
								<h6 className="title mb-4">Bill to if Different:</h6>
								<Row>
									<Col>
										<Form.Group className="mb-2">
											<Input label={`Property Name`} ids={`billToName`} value={userDetail.billToName} validate={false} handleChange={handleChange} />
										</Form.Group>
										<Form.Group className="mb-2">
											<Input label={`Address`} ids={`billToAddress`} value={userDetail.billToAddress} validate={false} handleChange={handleChange} />
										</Form.Group>
										<Form.Group className="mb-2">
											<Input label={`City`} ids={`billToCity`} value={userDetail.billToCity} validate={false} handleChange={handleChange} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Form.Group className="mb-2">
											<Input label={`State`} ids={`billToState`} value={userDetail.billToState} validate={false} handleChange={handleChange} />
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group className="mb-2">
											<Input label={`Zip`} ids={`billToZip`} value={userDetail.billToZip} validate={false} handleChange={handleChange} />
										</Form.Group>
									</Col>
								</Row><Row>
									<Col>
										<Form.Group className="mb-2">
											<Input label={`Phone`} ids={`billToPhone`} value={userDetail.billToPhone} validate={false} handleChange={handleChange} />
										</Form.Group>
										<Form.Group className="mb-2">
											<Input label={`Billing Email(s)`} ids={`billToEmail`} value={userDetail.billToEmail} validate={false} handleChange={handleChange} />
										</Form.Group>
									</Col>
								</Row>
							</Col>
							<Col xl={8} md={12}>
								<h6 className="title mb-4">Contacts:</h6>
								<div className="custom-contact-table">
								<Table responsive bordered>
									<thead>
										<tr>
											<th>Approval Rank</th>
											<th>Role</th>
											<th style={{minWidth: '200px'}}>Name</th>
											<th style={{minWidth: '200px'}}>Number</th>
											<th>Ext</th>
											<th style={{minWidth: '300px'}}>Email</th>
										</tr>
									</thead>
									<tbody>
									{contactViewModel && contactViewModel.length > 0 && contactViewModel.map((data, index) =>  (
										<tr key={index}>
											<td width="65">
												<Input 
													value={(contactViewModel.length>0 && contactViewModel[index] && contactViewModel[index].approvalRank) ? contactViewModel[index].approvalRank : null} 
													ids={`ApprovalRank`} 
													validate={false} 
													handleChange={(evt)=>handleContactChange(evt, index)} 
												/>
											</td>
											<td>
												<Select 
													result={roleListDetails}
													value={(contactViewModel.length>0 && 
														contactViewModel[index] &&
														contactViewModel[index].roll) ? 
														contactViewModel[index].roll :
														null}
													handleChange={(evt)=>handleContactChange(evt, index)} 
													ids="Roll"
													validate={false} 
												/>
											</td>
											<td>
												<Input value={(contactViewModel.length>0 && 
														contactViewModel[index] && 
														contactViewModel[index].name) ? 
														contactViewModel[index].name :
														null} 
													ids={`Name`} 
													validate={false} 
													handleChange={(evt)=>handleContactChange(evt, index)}
												/>
											</td>
											<td>
												<Input value={(contactViewModel.length>0 && contactViewModel[index] &&
													contactViewModel[index].number) ? 
													contactViewModel[index].number :
													null} 
													ids={`Number`} 
													validate={false} 
													handleChange={(evt)=>handleContactChange(evt, index)}
												 />
											</td>
											<td>
												<Input value={(contactViewModel.length>0 &&
													contactViewModel[index] &&
												 	contactViewModel[index].ext) ? 
													contactViewModel[index].ext : null} 
													ids={`Ext`} 
													validate={false} 
													handleChange={(evt)=>handleContactChange(evt, index)}
												/>
											</td>
											<td>
												<Input value={(contactViewModel.length>0 &&
													contactViewModel[index] && 
													contactViewModel[index].email) ? 
													contactViewModel[index].email : null} 
													ids={`Email`} 
													validate={false} 
													handleChange={(evt)=>handleContactChange(evt, index)}
												/>
											</td>
										</tr>
									))}
									</tbody>
								</Table>
								</div>
								<Button variant="primary" onClick={showUploadModal}>Upload Unit Mix</Button>
									<ImportCustomer
										importModalOpen={importModalOpen}
										hideImportModal={hideImportModal}
										handleImportFile={handleImportFile}
									/>
									<UnitMix 
										unitMixModalOpen={unitMixModalOpen}
										hideUnitMixModal={hideUnitMixModal}
										handleUnitMixFile={handleUnitMixFile}
										handleNewImportFile={handleNewImportFile}
										importedFileData={importedFileData}
										importedFileMessageData={importedFileMessageData}
										saveFileMessageData={saveFileMessageData}
									/>
									<Button variant="primary" onClick={goToEstimatesPageHandler}>Estimates</Button>
									{(userDetail?.viableSeamsFloorPlans && userDetail?.viableSeamsFloorPlans.length > 0) ? <span ref={target} className="pc-error">*</span> : null }
									<Overlay target={target.current} show={(userDetail?.viableSeamsFloorPlans && userDetail?.viableSeamsFloorPlans.length > 0)} placement="right">				
										{(props) => (
						          <Tooltip id="overlay" {...props} className="overlay-tooltip">
						            Floorplan &quot;{(userDetail?.viableSeamsFloorPlans && userDetail?.viableSeamsFloorPlans.length > 0) ? userDetail?.viableSeamsFloorPlans.join('/') : null }&quot; should be reviewed by estimating staff prior to issuing to customer.
						          </Tooltip>
						        )}
						      </Overlay>			
							</Col>
						</Row>
						<Row className="mb-5">
							<Col>
								<h6 className="title mb-4">Accounting/Setup:</h6>
								<Row className="align-items-end">
									<Col xl={2} md={2} sm={6}>
										<Form.Group className="mb-2">
											<Select label={`Store Name`} result={storeData} handleChange={handleStoreChange} value={storeId} ids="StoreId" validate={false} />
										</Form.Group>
									</Col>
									<Col xl={2} md={2} sm={6}>
										<Form.Group className="mb-2">
											<Select label={`Salesperson`} result={salesPersonList} value={userDetail.salesPersonId} handleChange={handleSalespersonChange} ids="salesPersonId" validate={false} />
										</Form.Group>
									</Col>
									<Col xl={2} md={2} sm={6}>
										<Form.Group className="mb-2">
											<Form.Label>Terms</Form.Label>
											<Form.Control type="text" />
										</Form.Group>
									</Col>
									<Col xl={1} md={2} sm={6}>
										<Form.Group className="mb-2">
											<Form.Label>Credit Limit</Form.Label>
											<Form.Control type="text" />
										</Form.Group>
									</Col>
									<Col>
										<PricingContract
											className="mb-2"
											storeId={storeId}
											contactInfoId={[userDetail.contactInfoId]}
											pricingContractModalOpen={pricingContractModalOpen}
											showPricingContractModal={showPricingContractModal}
											hidePricingContractModal={hidePricingContractModal}
											isContractPricingAdded={userDetail.isContractPricingAdded}
											changeReducerState={changeReducerState}
											handleUpdateCustomerDetail={handleUpdateCustomerDetail}
											selectedQuantityProduct={selectedQuantityProduct}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						{defaultlayoutData && defaultlayoutData.length>0
						? <Row className="mb-5">
								<Col>
									<h6 className="title mb-4">Default layout:</h6>
									<Row className="align-items-end">
										<Col className="plan-container">
											{defaultlayoutData && defaultlayoutData.length>0 ? (
												defaultlayoutData.map((value, index) => (
													<div className="floorplan-thumnail position-relative" key={index}>
														<div className="img" onClick={()=>handleLayoutNameClick(value)}>
															<img src={value?.orderImagePng ? value?.orderImagePng : value?.measureImagePng} />
														</div>
														<span className="position-relative only">
															<span>{value?.unitType}</span>
															<Icon title="Edit Unit Mix" role="button" className="excelView-btn bottom" size={18} icon={buildingO} onClick={()=>handleLayoutNameChange(value)} />
														</span>
															{JSON.parse(localStorage.getItem('showSeamCutPlan')) &&
																<CustomButton variant="primary" handleClick={() => handleSeamAndCutPlan(value)}>Seam & Cut Plan</CustomButton>
															}
													</div>
												))) : null
											}
										</Col>
									</Row>
								</Col>
							</Row> 
						: ''}
						<Row className="mb-5">
							<Col>
								<h6 className="title mb-4">Quantity Calculator:</h6>
								<Row className="align-items-end">
									<Col className="plan-container">
										{imageListing && imageListing.length>0 ? (
												imageListing.map((value, index) => (
													<div className="floorplan-thumnail position-relative" key={index}>
														<ImportImage
					                    handleImageFile={handleImageFile}
					                    value={value}
														/>
														<span className="position-relative" style={{cursor: value?.imagePath ? 'pointer' : ''}}>
															<span onClick={()=>handleImageNameClick(value)}>{value?.unitType}</span>
															<Icon title="Edit Unit Mix" role="button" className="excelView-btn bottom" size={18} icon={buildingO} onClick={()=>handleLayoutNameChange(value)} />
															<Icon title="Delete Unit Mix" role="button" className="trash-btn bottom" size={18} icon={trash} onClick={()=>handleDeleteUnitType(value)} />
														</span>
													</div>
												))
											) : null
										}
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
					{orderingModalOpen ? 	
						<SelectOrderMaterial 
							handleOrderingModelOpen={handleOrderingModelOpen}
							handleOrderingModelClose={handleOrderingModelClose}
							orderingModalOpen={orderingModalOpen}
							orderingUrl={orderingUrl}
						/> : ""
					}
					{editlayoutModalOpen ?
						<EditLayoutModal
							handleLayoutModelOpen={handleLayoutModelOpen}
							handleLayoutModelClose={handleLayoutModelClose}
							handleLayoutModalSave={handleLayoutModalSave}
							customerUnitMixData={customerUnitMixData}
							duplicatesUnitData={duplicatesUnitData}
							handleDuplicatesData={handleDuplicatesData}
						/> : ""
					}
					{measureModalOpen ?
						<MeasureModal
							handleMeasureModelOpen={handleMeasureModelOpen}
							handleMeasureModelClose={handleMeasureModelClose}
							measureModalOpen={measureModalOpen}
							measureUrl={measureUrl}
						/> : ""
					}
					{deleteUnitTypeModalOpen ?
						<Modal show={deleteUnitTypeModalOpen} size="md">
							<Modal.Header closeButton onClick={hideConfirmDeleteModal}>
								<div className="modal-title h4">Delete Floor Plans?</div>
							</Modal.Header>
							<Modal.Body className="text-center">
								<h5 className="mb-0">Are you sure? </h5>
								<p className="text-danger"><span>*</span>Unit type will also be deleted.</p>
							</Modal.Body>
							<Modal.Footer className="justify-content-center">
								<Button variant="primary" onClick={handleConfirmDeleteSubmit} id="model_delete" name="model_delete">
									Yes
								</Button>
								<Button variant="secondary" onClick={hideConfirmDeleteModal}>
									No
								</Button>
							</Modal.Footer>
						</Modal> : ""
					}
				</div>
		</MainWrapper>
	)
}

const mapStateToProps = ({ auth, customerInfo, StoreInfo, SalesPersonCategory, pricingContract, ContactRoll, Role }) => {
	return {
		auth, customerInfo, StoreInfo, SalesPersonCategory, pricingContract, ContactRoll, Role
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		fetchSalesPersonCategory: (token) => dispatch({ type: SALES_PERSON_CATEGORY_REQUEST, token }),
		fetchCustomerList: (token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked) => dispatch({ type: CUSTOMER_LIST_REQUEST, token, sort, page, paginate, listParamState, userId, salesPersonId, GrpPcId, isChecked }),
		updateCustomerDetails: (token, data) => dispatch({ type: CUSTOMER_UPDATE_REQUEST, token, data }),
		changeReducerState: () => dispatch({ type: CUSTOMER_STATE_CHANGE }),
		fetchStoreInfo: (token) => dispatch({ type: STORE_INFO_REQUEST, token }),
		fetchCarpetRollList: (token) => dispatch({ type: CONTACT_ROLL_LIST_REQUEST, token }),
		submitImportCustomerRequest: (data) => dispatch({ type: CUSTOMER_IMPORT_REQUEST, data }),
		submitImportImageCustomerRequest: (data, contactInfoId, unitTypeId) => dispatch({ type: CUSTOMER_IMPORT_IMAGE_REQUEST, data, contactInfoId, unitTypeId }),
		fetchUploadedImageRequest: (token, sort, page, paginate, contactInfoId, userId) => dispatch({ type: CUSTOMER_GET_IMAGE_REQUEST, token, sort, page, paginate, contactInfoId, userId}),
		saveImportCustomerUnitMixRequest: (token, payload, contactInfoId) => dispatch({ type: CUSTOMER_IMPORT_UNIT_MIX_SAVE_REQUEST, token, payload, contactInfoId }),
		fetchUnitMixListRequest: (token, payload) => dispatch({ type: CUSTOMER_UNIT_MIX_LIST_REQUEST, token, payload }),
		updateUnitMixListRequest: (token, payload) => dispatch({ type: CUSTOMER_UPDATE_UNIT_MIX_REQUEST, token, payload }),
		fetchRoleList: (token) => dispatch({ type: ROLE_LIST_REQUEST, token }),
		fetchMissingLaborItemsInContract: (token, contactInfoId) => dispatch({ type: PRICING_CONTRACT_MISSING_STAIRS_LABOR_REQUEST, token, contactInfoId }),
		getQuantitySetProduct: (token, contactInfoId) => dispatch({ type: PRICING_CONTRACT_QUANTITY_SET_PRODUCT_REQUEST, token, contactInfoId }),
		deleteUnitTypeRequest: (token, payload) => dispatch({ type: CUSTOMER_DELETE_UNIT_TYPE_REQUEST, token, payload }),
		fetchSalesPersonByStoreId: (token, storeId) => dispatch({ type: SALES_PERSON_REQUEST, token, storeId }),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);