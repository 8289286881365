import React, { Fragment } from "react";
import { Table } from 'react-bootstrap';


const EstimatesTable = ({
  estimatesData, customerInfo
}) => {

  // function to format a number with commas every 3 digits
  const handleFormat = (number) => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const showBadge = (name) => {
    return (customerInfo?.viableSeamsFloorPlans && customerInfo?.viableSeamsFloorPlans.length > 0 && customerInfo?.viableSeamsFloorPlans.includes(name)) ? 
      <span style={{ color: "red", margin: "0", padding: "0", "font-weight":"bold" }}>{name} * </span> : name
  } 


  return (
    <Fragment>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>
              Floor Plan
            </th>
            <th>
              Vinyl
            </th>
            <th>
              Vinyl Plank
            </th>
            <th>
              Carpet
            </th>
            <th>
              Quarter Round
            </th>
            <th>
              Tax
            </th>
            <th>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {estimatesData.length > 0 ? estimatesData.map((k, i) => (
            <tr key={i}>
              <td>{showBadge(k.floorPlanName)}</td>
              <td>{k.summarizedItem.vinyl?.linePrice === 0.0 ? "" : "$" + handleFormat(k.summarizedItem.vinyl?.linePrice)}</td>
              <td>${handleFormat(k.summarizedItem.vinylPlank?.linePrice)}</td>
              <td>${handleFormat(k.summarizedItem.carpet?.linePrice)}</td>
              <td>
                ${(handleFormat(k.summarizedItem?.wallbase.linePrice))}
              </td>
              <td>${handleFormat(k.summarizedItem.total.tax)}</td>
              <td>${handleFormat(k.summarizedItem.total.linePrice+ k.summarizedItem.total.tax)}</td>
            </tr>
          )) : null}

        </tbody>
      </Table>

      {estimatesData.length > 0 ? estimatesData.map((floorPlan, i) => (
      <Fragment key={i}>
      <div className="pagebreak" />
      <div key={i}>
      <img src={floorPlan.floorPlanImage} alt="Standard Logo" className="floor-plan-image" />
      <h3>Estimate for {showBadge(floorPlan.floorPlanName)}</h3>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>
              Line Item
            </th>
            <th>
              Price
            </th>
            <th>
              Tax
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><h4>Product</h4></td>
            <td></td>
          </tr>
          {floorPlan.itemizedEstimate.masterProduct.map((line, k) => (
          <tr key={k}>
            <td>{line.name}</td>
            <td>${handleFormat(line.linePrice)}</td>
            <td>${handleFormat(line.tax)}</td>
          </tr>
          ))}
          <tr>
            <td><h4>Material</h4></td>
            <td></td>
          </tr>
          {floorPlan.itemizedEstimate.material.map((line, k) => (
          <tr key={k}>
            <td>{line.name}</td>
            <td>${handleFormat(line.linePrice)}</td>
            <td>${handleFormat(line.tax)}</td>
          </tr>
          ))}
          <tr>
            <td><h4>Labor</h4></td>
            <td></td>
          </tr>
          {floorPlan.itemizedEstimate.labor.map((line, k) => (
          <tr key={k}>
            <td>{line.name}</td>
            <td>${handleFormat(line.linePrice)}</td>
            <td>${handleFormat(line.tax)}</td>
          </tr>
          ))}
        </tbody>
      </Table>
      </div>
      </Fragment>
      )) : null}



    </Fragment>
  )
}

export default EstimatesTable